/** @jsx jsx */
import {jsx} from 'theme-ui'
import Layout from 'components/Layout'
import AboutHeader from 'components/AboutHeader'
import {graphql, PageProps} from 'gatsby'
import {AboutQuery} from '../graphqlTypes'
import AboutWho from 'components/AboutWho'
import AboutWith from 'components/AboutWith'
import AboutTeam from 'components/AboutTeam'
import AboutValues from 'components/AboutValues'
import value1 from 'images/about-values-1.svg'
import value2 from 'images/about-values-2.svg'
import value3 from 'images/about-values-3.svg'
import value4 from 'images/about-values-4.svg'
import value5 from 'images/about-values-5.svg'
import value6 from 'images/about-values-6.svg'
import SEO from 'components/SEO'
import {PageContext} from 'gatsbyTypes'
import useAutoSelectLanguage from 'hooks/useAutoSelectLanguage'

const AboutUsPage: React.FC<PageProps<AboutQuery, PageContext>> = ({
  data,
  pageContext,
}) => {
  useAutoSelectLanguage(pageContext)
  return (
    <Layout>
      <SEO
        title="Our team, our mission, our values."
        description="Ummaty is An international muslim humanitarian organization, founded by people who share the same objective: fight poverty and help the most vulnerable amongst us to achieve a better future."
      />
      <AboutHeader
        data={data}
        subtitle="Une organisation humanitaire internationale de musulmane basée à 100% sur le bénévolat."
      />
      <AboutWho
        data={data}
        title="QUI NOUS SOMMES"
        content={[
          "Une organisation humanitaire musulmane internationale, fondée par des personnes partageant le même objectif: celui de combattre la pauvreté et d'aider à garantir un meilleur futur pour les plus vulnérables.",
          "Inspirés par nos valeurs islamiques, nous menons des projets dans différentes parties du monde, afin d'apporter des changements qui contribuent dans la lutte contre la pauvreté, promouvoir l'éducation, permettent un développement durable des communautés et apportent la dignité à tous.",
          '<b>Grâce à UMMATY</b>, et avec votre soutien, nous pouvons avoir un impact positif et changer durablement leurs vies.',
        ]}
        buttonText="Soyez le facteur de changement"
      />
      <AboutTeam
        data={data}
        title="NOTRE ÉQUIPE DE DIRECTION"
        text="La technologie fait de notre monde un petit village: avec un siège social au Canada, les membres de notre équipe de direction sont basés sur 3 continent différents et  travaillent ensemble tous les jours, pour livrer nos projets:"
        team={[
          {
            name: 'Badr Naciri Bennani',
            position: 'Lead Amérique du Nord - Manager Bureau principal',
            imageVariable: 'team1',
          },
          {
            name: 'Mouhcine Zerrad',
            position: 'Lead Programme Afrique du Nord',
            imageVariable: 'team2',
          },
          {
            name: 'Younes Lasri',
            position: 'Lead Programme Afrique Subsaharienne',
            imageVariable: 'team3',
          },
          // {
          //   name: 'Naoufal Rihani',
          //   position: 'Lead Programme Asie',
          //   imageVariable: 'team4',
          // },
          {
            name: 'Hassan Boussif',
            position: 'Lead Programme Canada',
            imageVariable: 'team5',
          },
          {
            name: 'Adil Naimi',
            position: 'Lead Programme Digital',
            imageVariable: 'team6',
          },
        ]}
      />
      <AboutWith
        data={data}
        text={`
      <h2>AVEC L'ÊTRE HUMAIN. OÙ QU'IL SOIT.</h2>
      <p>Offrir une assistance aux personnes vulnérables à travers le monde, en fournissant des services humanitaires, incluant des programmes tels que la réduction de la pauvreté, l'approvisionnement en eau potable, l'assainissement, l'éducation et l'aide humanitaire aux victimes de catastrophes naturelles ou d'origine humaine.</p>
      <h2>MODÈLE 0% FRAIS DE GESTION</h2>
      <p>Être la première organization humanitaire internationale musulmane consacrant l'intégralité des dons perçus à la réalisation des projets (modèle 0% de frais de gestion). Oeuvrer à travers ces projets à l'amélioration des conditions de vie des communautés à travers le monde et contribuer ainsi au maintien et à la préservation de la dignité humaine.
      </p>
      `}
        buttonText="Soyez le facteur de changement"
      />
      <AboutValues
        title="NOS VALEURS FONDAMENTALES"
        items={[
          {
            title: 'Sincérité',
            description:
              "Nous croyons en la sincérité; dans l'adéquation des intentions avec nos actions et la nécessité de remplir nos obligations envers l'humanité.",
            icon: value1,
          },

          {
            title: 'Excellence',
            description:
              "Nous nous efforçons d'atteindre les normes de qualité les plus élevées et croyons en l'efficacité du travail d'équipe, l'innovation, la créativité et au professionnalisme lorsque nous effectuons nos missions et réalisons de nos projet.",
            icon: value3,
          },

          {
            title: 'Non-discrimination',
            description:
              "Nous croyons en la valeur de chaque être humain et en la nécessité de respecter et d'honorer chaque individu; quel que soit sa race, religion, origine ou sexe.",
            icon: value5,
          },
          {
            title: 'Intégrité',
            description:
              "Nous croyons en l'intégrité et en la responsabilité dans la conduite de tous nos projets temporaires et permanents. La corruption, les conflits d'intérêts et la fraude ne sont pas tolérés. Partout où nous opérons, nous nous engageons à respecter les lois et règlements applicables.",
            icon: value2,
          },
          {
            title: 'Transparence',
            description:
              "Nous croyons que le dépôt qui nous est confié et la confiance que les gens nous accordent exigent une transparence absolue avec les donateurs, les partenaires et les personnes que nous assistons. Nous sommes les gestionnaires responsables des fonds qui nous sont confiés et nous nous efforçons d'éviter toute dépense inutile.",
            icon: value4,
          },
          {
            title: 'Bénévolat',
            description:
              "Nous promouvons le bénévolat car nous croyons en l'altruisme et aux actes désintéressés. En faisant la promotion du bénévolat, en particulier chez les jeunes, nous participons à la construction de communautés qui prennent soin des plus vulnérables et qui trouvent leur bonheur dans le travail pour le bien-être des autres.",
            icon: value6,
          },
        ]}
      />
    </Layout>
  )
}

export default AboutUsPage

export const pageQuery = graphql`
  query AboutFR {
    ...AboutHeader
    ...AboutWho
    ...AboutTeam
    ...AboutWith
  }
`
